<template>
    <component v-if="isReady" :is="component" :value="value"></component>
</template>

<script>
import { defineComponent, onBeforeMount, ref } from 'vue';
import useColumns from '../services/useColumns';

export default defineComponent({
    name: 'ColumnComponentWrapper',
    props: { th: Object, value: [Number, String, Object] },
    setup(props) {
        const isReady = ref(false);
        const component = ref();
        const { getComponent } = useColumns();
        onBeforeMount(async () => {
            component.value = await getComponent(props.th.component);
            isReady.value = true;
        });
        return { isReady, component };
    },
});
</script>
