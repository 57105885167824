export default function usePagination() {
    const generatePagination = (itemTotal, currentPage, itemLimit) => {
        const lastPage = Math.ceil(itemTotal / itemLimit);
        let i = 1;

        const pages = [];
        if (currentPage > Math.ceil(itemLimit / 2)) {
            // eslint-disable-next-line no-plusplus
            i = currentPage - Math.floor(itemLimit / 2);
        }
        for (; pages.length < itemLimit && i <= lastPage; i += 1) {
            pages.push(i);
        }

        return pages;
    };

    return { generatePagination };
}
