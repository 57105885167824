export default async () => {
    /**
     * Modules
     */
    let careComponents = [];
    // Darmin Care
    if (process.env.VUE_APP_DARMIN_CARE) {
        careComponents = await import('./modules/Care/CareComponents');
    }
    return {
        components: [...careComponents.default],

        getComponent(title) {
            return this.components.find((obj) => obj.title === title);
        },
    };
};
