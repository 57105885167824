<template>
    <div class="d-flex gap-1">
        <i
            class="mt-auto pb-1 far fa-trash-alt text-danger"
            @click="handleRemove"
            v-show="remove"
        ></i>
        <select
            v-show="remove"
            id=""
            class="darmin__simple_field form-control-sm"
            v-model="field.condition"
        >
            <option value=""></option>
            <option value="and">y</option>
            <option value="or">o</option>
        </select>
        <select id="" class="darmin__simple_field form-control-sm" v-model="field.field">
            <option value=""></option>
            <option v-for="(f, i) in fields" :key="i" :value="f.value">{{ f.name }}</option>
        </select>
        <select id="" class="darmin__simple_field form-control-sm" v-model="field.equal">
            <option value="is">es</option>
            <option value="isNot">no es</option>
            <option value="gt" v-if="fieldSelected?.type === 'Date'">Es mayor a</option>
            <option value="lt" v-if="fieldSelected?.type === 'Date'">Es menor a</option>
        </select>
        <template v-if="fieldSelected?.type === 'Select'">
            <select id="" class="darmin__simple_field form-control-sm" v-model="field.value">
                <option v-for="(o, i) in fieldSelected.options" :key="i" :value="o.value">
                    {{ o.name }}
                </option>
            </select>
        </template>
        <template v-if="fieldSelected?.type === 'Date'">
            <DateInput v-model="field.value" size="form-control-sm" />
        </template>
        <input
            v-if="fieldSelected?.type === 'String'"
            type="text"
            class="darmin__simple_field form-control-sm"
            v-model="field.value"
        />
        <span class="mt-auto" v-show="coma">,</span>
    </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { computed, reactive, onBeforeMount, ref } from 'vue';
// import { DateTime } from 'luxon';
import DateInput from '../../form/DateInput.vue';

export default {
    name: 'FielterField',
    components: { DateInput },
    props: {
        coma: { type: Boolean, default: false },
        remove: { type: Boolean, default: true },
        modelValue: { type: Object },
        filters: { type: Array },
    },
    setup(props, { emit }) {
        const localField = reactive({});
        const fielType = ref('String');
        onBeforeMount(() => {
            Object.keys(props.modelValue).forEach(
                // eslint-disable-next-line no-return-assign, comma-dangle
                (k) => (localField[k] = props.modelValue.value[k])
            );
        });
        const field = computed({
            get() {
                Object.keys(props.modelValue).forEach(
                    // eslint-disable-next-line no-return-assign, comma-dangle
                    (k) => (localField[k] = props.modelValue[k])
                );
                return props.modelValue;
            },
        });

        const handleRemove = () => {
            // console.log(props.modelValue);
            emit('onRemove', props.modelValue.id);
        };
        const fields = computed(() => {
            // console.log('filters', props.filters);
            const selectFields = [];
            if (props.filters.length) {
                props.filters.forEach((f) => {
                    switch (f.type) {
                        case 'foreign':
                            f.fields.forEach((ff) => {
                                selectFields.push({
                                    value: `${f.field}.${ff.field}`,
                                    name: ff.name,
                                });
                            });
                            break;
                        default:
                            selectFields.push({ value: f.field, name: f.name });
                            break;
                    }
                });
            }
            // console.log(selectFields);
            return selectFields;
        });

        const fieldSelected = computed(() => {
            // console.log(fields.value);
            if (fields.value) {
                // console.log(localField);
                if (Object.keys(localField)) {
                    return props.filters.find((f) => f.field === localField.field);
                }
            }
            return undefined;
        });

        return {
            handleRemove,
            field,
            localField,
            fields,
            fielType,
            fieldSelected,
        };
    },
};
</script>
