<template>
    <WindowLayout :title="title">
        <div class="row">
            <div class="col-lg-12">
                <div class="darmin card header-card">
                    <Filters
                        :filters="filters"
                        @onFilter="handleFilterData"
                        @onSearch="handleSearchValue"
                    ></Filters>
                </div>
                <div class="darmin card">
                    <div class="card-block row">
                        <div class="col-sm-12 col-lg-12 col-xl-12">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th
                                                v-for="(th, i) in columns"
                                                :key="i"
                                                :width="th.width + '%'"
                                            >
                                                {{ th.title }}
                                            </th>
                                            <th align="right" width="10%" class="text-center">
                                                ...
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="item in listItem" :key="item._id">
                                            <td v-for="(th, i) in columns" :key="i">
                                                <ColumnComponentWrapperVue
                                                    v-if="th.component !== undefined"
                                                    :th="th"
                                                    :value="getAttr(item, th.attr)"
                                                />
                                                <div v-else>{{ getAttr(item, th.attr) }}</div>
                                            </td>
                                            <td align="right">
                                                <i
                                                    @click="handleAction(o, item)"
                                                    v-for="(o, i) in options"
                                                    :key="i"
                                                    class="far cursor-pointer"
                                                    :class="'fa-' + o.icon"
                                                ></i>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="row">
                            <div class="col">{{ count }} items</div>
                            <div class="col text-end">
                                <div class="btn-group pagination" id="" v-if="count">
                                    <button
                                        class="btn btn-sm btn-outline-primary px-3 previous"
                                        :class="{
                                            'd-none': Number(currentPage) === 1,
                                        }"
                                        aria-controls="basic-1"
                                        data-dt-idx="0"
                                        tabindex="0"
                                        id="basic-1_previous"
                                        @click="prevPage()"
                                    >
                                        Previous</button
                                    ><button
                                        v-for="(x, i) in pagination"
                                        :key="i"
                                        :class="{
                                            active: x === currentPage,
                                            'btn-info': x === currentPage,
                                            'btn-outline-primary': x !== currentPage,
                                        }"
                                        class="btn btn-sm px-3"
                                        :aria-controls="`basic-${1}`"
                                        :data-dt-idx="x"
                                        tabindex="0"
                                        @click="setPage(x)"
                                    >
                                        {{ x }}</button
                                    ><button
                                        class="btn btn-sm btn-outline-primary px-3 next"
                                        :class="{
                                            'd-none':
                                                Number(currentPage) ===
                                                pagination[pagination.length - 1],
                                        }"
                                        aria-controls="basic-1"
                                        data-dt-idx="7"
                                        tabindex="0"
                                        id="basic-1_next"
                                        @click="nextPage()"
                                    >
                                        Next
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </WindowLayout>
</template>

<script>
import { onBeforeMount, ref, computed } from 'vue';
import { useStore } from 'vuex';
import useSocket from '@/services/useSocket';
import axios from '@/services/useAxios';
import WindowLayout from '@/layouts/Window.layout.vue';
import ColumnComponentWrapperVue from './components/ColumnComponentWrapper.vue';
import Filters from './components/Filters.vue';
import usePagination from '../../services/usePagination';

export default {
    name: 'DarminList',
    components: { WindowLayout, ColumnComponentWrapperVue, Filters },
    props: {
        title: { type: String, default: 'Lista' },
        options: Array,
        columns: Array,
        urlQuery: { type: String, required: true },
        listener: String,
    },
    setup(props) {
        const store = useStore();
        const { socket } = useSocket();
        const count = ref(0);
        const currentPage = ref(1);
        const itemsLimit = ref(30);
        const listItem = ref([]);
        const filters = ref([]);
        const filtersMode = ref(false);
        const filterQuery = ref('');
        const searchQuery = ref('');
        const { generatePagination } = usePagination();

        const getData = async () => {
            const [{ data }] = await Promise.all([
                axios.get(props.urlQuery, {
                    params: { page: currentPage.value, limit: itemsLimit.value },
                }),
            ]);
            count.value = data.count;
            listItem.value = data.items;
            filters.value = data.filters;
        };

        // eslint-disable-next-line arrow-body-style
        const pagination = computed(() => {
            return generatePagination(count.value, currentPage.value, itemsLimit.value);
        });

        const filterData = async () => {
            const [{ data }] = await Promise.all([
                axios.get(props.urlQuery, {
                    params: {
                        page: currentPage.value,
                        limit: itemsLimit.value,
                        filters: filterQuery.value,
                    },
                }),
            ]);
            count.value = data.count;
            listItem.value = data.items;
        };

        const handleFilterData = async (value) => {
            filtersMode.value = true;
            filterQuery.value = value;
            currentPage.value = 1;
            filterData();
        };

        const searchValue = async () => {
            const [{ data }] = await Promise.all([
                axios.get(props.urlQuery, {
                    params: {
                        page: currentPage.value,
                        limit: itemsLimit.value,
                        searchQuery: searchQuery.value,
                    },
                }),
            ]);
            count.value = data.count;
            listItem.value = data.items;
        };

        const handleSearchValue = async (value) => {
            searchQuery.value = value;
            filtersMode.value = false;
            currentPage.value = 1;
            searchValue();
        };

        onBeforeMount(() => {
            getData();
        });

        const setPage = (page) => {
            currentPage.value = page;
            if (filtersMode.value) {
                filterData();
            } else {
                searchValue();
            }
        };
        const nextPage = () => {
            currentPage.value += 1;
            if (filtersMode.value) {
                filterData();
            } else {
                searchValue();
            }
        };
        const prevPage = () => {
            currentPage.value -= 1;
            if (filtersMode.value) {
                filterData();
            } else {
                searchValue();
            }
        };

        const handleAction = (option, item) => {
            store.dispatch('ContentManager/openWindow', {
                ...option,
                id: `${option.id}_${item.id}`,
                name: `${option.name}${item.consecutive ? ` ${item.consecutive}` : ''}`,
                params: { ...option, item },
            });
        };

        const getAttr = (object, attrib) => {
            if (attrib === 'self') {
                return object;
            }
            const array = attrib.split('.');
            let result = object;
            // eslint-disable-next-line no-restricted-syntax
            for (const key of array) {
                if (Array.isArray(result)) {
                    const t = [];
                    // eslint-disable-next-line no-restricted-syntax
                    for (const r of result) {
                        t.push(r[key]);
                    }
                    result = t.join(' - ');
                } else if (Object.keys(result).includes(key)) {
                    result = result[key];
                } else {
                    return '-';
                }
            }
            return result;
        };

        if (props.listener) {
            socket.on(`rte:${props.listener}`, async () => {
                if (filtersMode.value) {
                    filterData();
                } else {
                    searchValue();
                }
            });
        }

        return {
            count,
            listItem,
            filters,
            handleAction,
            handleFilterData,
            handleSearchValue,
            getAttr,
            pagination,
            currentPage,
            setPage,
            nextPage,
            prevPage,
        };
    },
};
</script>

<style scoped>
.pagination {
    display: inline-flex;
}

tbody tr:hover {
    background-color: var(--background-hover);
}
</style>
