<template>
    <div class="row">
        <div
            v-if="filters?.length > 0"
            class="
                col-lg-6
                order-2 order-lg-1
                d-flex
                align-items-center
                toogle-button
                collapsed
                cursor-pointer
                mt-3 mt-lg-0
            "
            data-bs-toggle="collapse"
            :data-bs-target="`#card-${ramId}`"
            aria-expanded="false"
            :aria-controls="`card-${ramId}-heading`"
        >
            <u>Filtrar</u>
            <i class="ms-2 fas fa-chevron-down"></i>
        </div>
        <div class="col-lg-5 order-1 order-lg-2 ms-auto text-end">
            <div>
                <div class="input-group">
                    <input
                        class="form-control form-control-sm"
                        type="text"
                        aria-label="Amount (to the nearest dollar)"
                        v-model="searchQuery"
                        v-on:keyup.enter="handleSearch"
                    />
                    <span class="btn btn-sm btn-primary input-group-text" @click="handleSearch">
                        <i class="far fa-search"></i>
                    </span>
                </div>
            </div>
        </div>
        <div
            v-if="filters?.length > 0"
            class="toggle-content collapse col-12 order-3"
            :id="`card-${ramId}`"
        >
            <div class="pt-3">
                <div class="d-flex flex-wrap gap-3">
                    <FilterField
                        :key="-1"
                        v-model="filterField"
                        :remove="false"
                        :filters="filters"
                    />
                    <FilterField
                        v-for="(f, i) in filterFields"
                        :key="f.id"
                        v-model="filterFields[i]"
                        @onRemove="handleRemoveField"
                        :filters="filters"
                    />
                    <div class="mt-auto">
                        <a
                            href="javascript:void(0)"
                            class="btn btn-outline-primary py-0 px-2"
                            @click="handleAddFilterField"
                        >
                            <i class="far fa-plus"></i>
                        </a>
                    </div>
                    <div class="ms-auto">
                        <button
                            href="javascript:void(0)"
                            class="btn btn-primary"
                            @click="handleFilter"
                        >
                            Filtrar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import { v4 as uuid } from 'uuid';
import FilterField from './FilterField.vue';

export default {
    name: 'ListFIlters',
    components: { FilterField },
    props: {
        filters: {
            type: Object,
        },
    },
    setup(props, { emit }) {
        const ramId = ref(Math.floor(Math.random() * 1000));
        const searchQuery = ref('');
        const filterField = ref({
            id: uuid(),
            field: '',
            value: '',
            condition: '',
            equal: '',
        });
        const filterFields = ref([]);

        const handleAddFilterField = () => {
            filterFields.value.push({
                id: uuid(),
                field: '',
                value: '',
                condition: '',
                equal: '',
            });
        };

        const handleRemoveField = (id) => {
            const index = filterFields.value.findIndex((f) => f.id === id);
            if (index > -1) filterFields.value.splice(index, 1);
        };

        const handleFilter = () => {
            const filterArray = [filterField.value, ...filterFields.value];
            const jsonString = JSON.stringify(filterArray);
            const encoded = encodeURIComponent(jsonString);
            emit('onFilter', encoded);
        };

        const handleSearch = () => {
            emit('onSearch', searchQuery.value);
        };

        return {
            ramId,
            filterField,
            filterFields,
            searchQuery,
            handleAddFilterField,
            handleRemoveField,
            handleFilter,
            handleSearch,
        };
    },
};
</script>

<style scoped>
</style>
